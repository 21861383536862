<template>
  <main class="content">
    <div class="inner content-grid">
      <div class="left">
        <page-heading heading="Scheduled Orders" icon="scheduled-orders-o.png" para="See scheduled & future orders below."
          v-if="$route.name == 'Scheduled Orders'"></page-heading>
        <page-heading heading="Order History" icon="order-history-o.png" para="See historic  orders placed below."
          v-if="$route.name == 'Order History'"></page-heading>

        <Orders-Table />
      </div>
      <right-column></right-column>
    </div>
  </main>
</template>

<script>
import OrdersTable from "../components/OrdersTable.vue"

export default {
  components: {
    OrdersTable
  }
}
</script>
